import React from 'react';

import LoadingDots from 'common-ui-components/LoadingDots';
import SingularPluralNouns, { getAmountLabel } from 'global/lists/SingularPluralNouns';

import style from 'screens/platform/directory/components/ProfileTabs/components/InteractionsCount/style.module.scss';

interface Props {
  filteredAtomsCount: number;
  loading: boolean;
}

const MAX_COUNT_DISPLAY = 1000;

export default function InteractionsCount({ filteredAtomsCount, loading }: Props) {
  const title = filteredAtomsCount < MAX_COUNT_DISPLAY
    ? getAmountLabel(filteredAtomsCount, SingularPluralNouns.result)
    : `${MAX_COUNT_DISPLAY}+ ${SingularPluralNouns.result.plural}`;

  return (
    <div className={style.interactionsStatus}>
      {loading ? <LoadingDots /> : title}
    </div>
  );
}
